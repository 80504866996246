<template>
  <div class="login">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <div class="px-5 mb-10 mx-auto">
            <img src="/logo.jpg" alt="Logo A & M" width="200">
          </div>

          <v-form ref="formLogin" v-model="validFormLogin">
            <div class="px-8 mb-8">
              <v-text-field
                label="Correo electrónico"
                type="text"
                v-model="model.email"
                :rules="rules.email"
              ></v-text-field>

              <v-text-field
                label="Contraseña"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                v-model="model.password"
                :rules="rules.password"
              ></v-text-field>
            </div>

            <v-btn
              color="secondary"
              :loading="this.$store.state.login.isLoading"
              rounded
              block
              large
              @click="login"
            >
              Entrar
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      validFormLogin: false,
      model: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          (v) => !!v || "El correo es obligatorio",
          (v) => /.+@.+\..+/.test(v) || "Debe ser un correo valido",
        ],
        password: [(v) => !!v || "La contraseña es obligatoria"],
      },
    };
  },
  methods: {
    login() {
      /* Validate form */
      if (this.$refs.formLogin.validate()) {
        this.$store.dispatch("login", this.model);
      }
    },
  },
};
</script>

<style scoped>
.login {
  height: 100%;
}
.login__btn_restart_passs {
  text-transform: none;
  letter-spacing: normal;
}
.login__btn_restart_passs:hover {
  background: none;
}
</style>
